import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  Button,
  Checkbox,
  CircularProgress,
  TableContainer,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import { DataContext } from "../../context/AppData";
import { useNavigate } from "react-router-dom";
import EventPopups from "../Reusable/EventPopups";
import Tooltip from "@mui/material/Tooltip";
import { baseUrl } from "../../apiConfig";

import CustomPopover from "../Reusable/CustomPopover";
import BrokenImageOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
const PunchListDetailRow = ({
  headers,
  item,
  rowIndex,
  expandedRow,
  setPlDetailId,
  setPunchData,
  fetchFilterdPunchList,
  setselectedPL,
  plId,
  statusloading, setStatusloading,customerId
}) => {
  const { PunchDetailData, setPunchDetailData, setPunchListData,PunchListData } =
    useContext(DataContext);
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCostItem, setSelectedCostItem] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("");
  const [snackBarText, setSnackBarText] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  // const [statusloading, setStatusloading] = useState(false);
  const [setSelectedRow, setSetSelectedRow] = useState(0);

  const handleCheckboxChange = (items) => {
    if (selectedItems.includes(items)) {
      // If item is already in the selectedItems array, remove it
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== items)
      );
    } else {
      // If item is not in the selectedItems array, add it
      setSelectedItems([...selectedItems, items]);
    }

    // console.log("selected item is", selectedCostItem);
  };

  useEffect(() => {
    const updatedSelectedItems = selectedItems.map((items) => ({
      ...items,
      isCost: false,
    }));

    setPunchListData((prevData) => ({
      ...prevData,
      // RegionalManagerId: item.Data.AssignedTo,
      RegionalManagerId: item.Data.CreatedBy,
      ContactId: item.Data.ContactId,
      PunchlistId: item.Data.PunchlistId,
      CustomerId: item.Data.CustomerId,
      CustomerDisplayName: item.Data.CustomerDisplayName,

      // PhotoPath: item.DetailDataList.DetailData.PhotoPath,
      // AfterPhotoPath: item.DetailDataList.DetailData.AfterPhotoPath,
      ItemData: updatedSelectedItems,
    }));
    // console.log("itemitemitemitem",item)
  }, [selectedItems]);

  const deletePunchListDetail = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/PunchList/DeletePunchlistDetail?id=${id}`,
        {
          headers,
        }
      );
      setOpenSnackBar(true);
      setSnackBarColor("error");
      setSnackBarText("Punch List detail deleted successfuly");
      fetchFilterdPunchList();
      console.log("delete pl details response", response.data);
    } catch (error) {
      console.log("There was an error deleting the punch list detail:", error);
      setOpenSnackBar(true);
      setSnackBarColor("error");
      setSnackBarText("Error deleting punch list detail");
    }
  };

  const changePlStatus = async (id, status) => {
   
    setStatusloading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/PunchList/UpdatePunchlistDetailStatus?PunchlistDetailId=${id}&StatusId=${status}`,
        {
          headers,
        }
      );
      setOpenSnackBar(true);
      setSnackBarColor("success");
      setSnackBarText("Punch List Status Changed successfuly");
      fetchFilterdPunchList("", 1, 10, 0, false, () => {
        setStatusloading(false);
      }, false);
      setSelectedItems([]);
      setShowStatus(false);
      console.log("delete pl details response", response.data);
    } catch (error) {
      console.log("There was an error deleting the punch list detail:", error);
      setShowStatus(false);
      setOpenSnackBar(true);
      setSnackBarColor("error");
      setSnackBarText(
        "There was an error changing status of punch list detail:"
      );
    }
  };

  const handleDelete = (id) => {
    deletePunchListDetail(id);
  };
  return (
    <>
      <EventPopups
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        color={snackBarColor}
        text={snackBarText}
      />
      {item.DetailDataList.map((detail, i) => {
        return (
          <TableRow key={i} style={{ borderBottom: 0 }}>
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
              colSpan={12}
            >
              <Collapse
                in={expandedRow === rowIndex}
                timeout="auto"
                unmountOnExit
                style={{
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <TableContainer sx={{ overflowX: "auto", width: "90%" }}>
                    <Table
                      size="small"
                      aria-label="purchases"
                      style={{ border: "none",  }}
                    >
                      <TableRow
                        style={{
                          borderLeft: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        <TableCell style={{ width: "7%" }}>
                          <div className="products">
                            {detail.DetailData.PhotoPath ? (
                              <>
                                {" "}
                                <a
                                  href={`${baseUrl}/${detail.DetailData.PhotoPath}`}
                                  target="_blank" // This attribute opens the link in a new tab
                                  rel="noopener noreferrer" // Recommended for security reasons
                                >
                                  <img
                                    src={`${baseUrl}/${detail.DetailData.PhotoPath}`}
                                    className="avatar avatar-md"
                                    alt="PunchList Image"
                                  />
                                </a>
                              </>
                            ) : (
                              <div
                                style={{
                                  padding: 3,
                                  backgroundColor: "#e6e6e6",
                                  borderRadius: "4px",
                                }}
                              >
                                <BrokenImageOutlinedIcon
                                  style={{ fontSize: 40, color: "#666" }}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell style={{ width: "22%", paddingLeft: 0 }}>
                          <div className="products">
                            <div>
                              <Tooltip title={detail.DetailData.Notes} arrow>
                                <h6
                                  style={{
                                    fontSize : "11px",
                                    whiteSpace: "wrap",
                                    overflow: "hidden",
                                    lineHeight : "1em"
                                    
                                  }}
                                >
                                  {detail.DetailData.Notes}
                                </h6>
                              </Tooltip>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: "13%" }}>
                          <CustomPopover
                            trigger={
                              <div className="row justify-content-center">
                                <div
                                  style={{
                                    width: "85%",
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  <Tooltip
                                    title="Change status of Punchlist Detail"
                                    arrow
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor:
                                          detail.DetailData
                                            .PunchlichlistDetailColor,
                                      }}
                                      className="badge badge-pill "
                                      onClick={() => {
                                        setShowStatus(true);
                                        setSetSelectedRow(
                                          detail.DetailData.PunchlistDetailId
                                        );
                                      }}
                                    >
                                      {
                                        detail.DetailData
                                          .PunchlichlistDetailStatus
                                      }
                                    </span>
                                  </Tooltip>
                                </div>
                                <div
                                  style={{
                                    width: "15%",
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                  }}
                                >
                                  {statusloading == true &&
                                    setSelectedRow ==
                                      detail.DetailData.PunchlistDetailId && (
                                      <CircularProgress size={20} />
                                    )}
                                </div>
                              </div>
                            }
                          >
                            <div
                              style={{
                                padding: "1em",
                                width: "6em",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    detail.DetailData.StatusId == 1
                                      ? "#FFC107"
                                      : "#28A745",
                                }}
                                className="badge badge-pill "
                                onClick={() => {
                                  changePlStatus(
                                    detail.DetailData.PunchlistDetailId,
                                    detail.DetailData.StatusId == 1 ? 2 : 1
                                  );
                                }}
                              >
                                {detail.DetailData.StatusId == 1
                                  ? "Pending"
                                  : "Complete"}
                              </span>
                            </div>
                          </CustomPopover>
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          {detail.ItemData.map((item, i) => {
                            return (
                              <div key={i}>
                                <Checkbox
                                  checked={selectedItems.includes(item)}
                                  onChange={() => handleCheckboxChange(item)}
                                />
                                <span>{item.Name}</span>
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell className="Punch-Detail-Link">
                          <div className="row">
                            <Tooltip title="Copy to Estimate" arrow>
                              <div
                                style={{ borderRight: "1px solid #999" }}
                                className="Punch-Link-Button mb-1 col-6 "
                                onClick={() => {
                                  // console.log("selected pl is",item.Data)
                                  setPunchListData((prevData) => ({
                                    ...prevData,
                                    CustomerId:customerId,
                                    EstimateNotes: detail.DetailData.Notes,
                                    RegionalManagerId: item.Data.AssignedTo,
                                    PhotoPath: detail.DetailData.PhotoPath,
                                    CustomerDisplayName: item.Data.CustomerDisplayName,
                                    AfterPhotoPath:
                                      detail.DetailData.AfterPhotoPath,
                                  }));
console.log("details",{
  ...PunchListData,
  CustomerId:customerId,
  EstimateNotes: detail.DetailData.Notes,
  RegionalManagerId: item.Data.AssignedTo,
  PhotoPath: detail.DetailData.PhotoPath,
  CustomerDisplayName: item.Data.CustomerDisplayName,
  AfterPhotoPath:
    detail.DetailData.AfterPhotoPath,
})
                                  navigate("/estimates/add-estimate");
                                }}
                              >
                                <p className="me-1">
                                  <span className="fa fa-plus me-1"></span>{" "}
                                  <span style={{ textDecoration: "underline" }}>
                                    Estimate
                                  </span>
                                </p>
                              </div>
                            </Tooltip>
                            <Tooltip title="Copy to Service Request" arrow>
                              <div
                                className="Punch-Link-Button col-6 ms-1"
                                onClick={() => {
                                  setPunchListData((prevData) => ({
                                    ...prevData,
                                    // RegionalManagerId: item.Data.AssignedTo,
                                    RegionalManagerId: item.Data.CreatedBy,
                                    ContactId: item.Data.ContactId,
                                    PunchlistId: item.Data.PunchlistId,
                                    CustomerId: item.Data.CustomerId,
                                    CustomerDisplayName: item.Data.CustomerDisplayName,
                                    WorkRequest: detail.DetailData.Notes,
                                    PhotoPath: detail.DetailData.PhotoPath,
                                    AfterPhotoPath:
                                      detail.DetailData.AfterPhotoPath,
                              
                                   
                                  }));
                                  navigate("/service-requests/add-sRform");
                                }}
                              >
                                <p>
                                  <span className="fa fa-plus me-1"></span>
                                  <span style={{ textDecoration: "underline" }}>
                                    Service Request
                                  </span>
                                </p>
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Edit Punchlist Detail" arrow>
                            <Button
                              className="delete-button"
                              data-bs-toggle="modal"
                              data-bs-target="#addPhotos"
                              onClick={() => {
                                setPunchDetailData(detail);
                                setselectedPL(detail.DetailData.PunchlistId);
                                // console.log("detail", detail);
                              }}
                            >
                              <Edit />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete Punchlist Detail" arrow>
                            <Button
                              color="error"
                              className="delete-button"
                              data-bs-toggle="modal"
                              data-bs-target={`#deletePlDetailsModal${detail.DetailData.PunchlistDetailId}`}
                            >
                              <Delete />
                            </Button>
                          </Tooltip>

                          <div
                            className="modal fade"
                            id={`deletePlDetailsModal${detail.DetailData.PunchlistDetailId}`}
                            tabIndex="-1"
                            aria-labelledby="deleteModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Punch List Detail Delete
                                  </h5>

                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                  ></button>
                                </div>
                                <div className="modal-body text-center">
                                  <p>
                                    Are you sure you want to delete{" "}
                                    {detail.DetailData.PunchlistDetailId}
                                  </p>
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    id="closer"
                                    className="btn btn-danger light "
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="btn btn-primary "
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                      handleDelete(
                                        detail.DetailData.PunchlistDetailId
                                      );
                                    }}
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default PunchListDetailRow;
